.orange {
  color: #ff7a01;
}

.form-container {
  max-width: 700px;
  margin: 30px;
  background-color: #001925;
  padding: 30px;
  border-left: 5px solid #165733;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 20px), calc(100% - 20px) 100%, 0 100%);
}

.heading {
  display: block;
  color: white;
  font-size: 1.5rem;
  font-weight: 800;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .heading {
    display: block;
    color: white;
    font-size: 1.1rem;
    font-weight: 800;
    margin-bottom: 20px;
  }
}

.form-container .form .input {
  color: #87a4b6;
  width: 100%;
  background-color: #002733;
  border: none;
  outline: none;
  padding: 10px;
  margin-bottom: 20px;
  font-weight: bold;
  transition: all 0.2s ease-in-out;
  border-left: 1px solid transparent;
}

.form-container .form .input:focus {
  border-left: 5px solid #165733;
}

.form-container .form .textarea {
  width: 100%;
  padding: 10px;
  border: none;
  outline: none;
  background-color: #013747;
  color: #1aad5c;
  font-weight: bold;
  resize: none;
  max-height: 150px;
  margin-bottom: 20px;
  border-left: 1px solid transparent;
  transition: all 0.2s ease-in-out;
}

.form-container .form .textarea:focus {
  border-left: 5px solid #165733;
}

.form-container .form .button-container {
  display: flex;
  gap: 10px;
}

.form-container .form .button-container .send-button {
  flex-basis: 70%;
  background: #165733;
  padding: 10px;
  color: #001925;
  text-align: center;
  font-weight: bold;
  border: 1px solid transparent;
  transition: all 0.2s ease-in-out;
}

.form-container .form .button-container .send-button:hover {
  background: transparent;
  border: 1px solid #165733;
  color: #1aad5c;
}

.form-container .form .button-container .reset-button-container {
  filter: drop-shadow(1px 1px 0px #165733);
  flex-basis: 30%;
}

.form-container .form .button-container .reset-button-container .reset-button {
  position: relative;
  text-align: center;
  padding: 10px;
  color: #1aad5c;
  font-weight: bold;
  background: #001925;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0 100%);
  transition: all 0.2s ease-in-out;
}

.form-container .form .button-container .reset-button-container .reset-button:hover {
  background: #165733;
  color: #001925;
}

.error-text {
    font-size: 12px;
    color: red;
}
