/* CodePen Card */

.cardTrabalhos {
    width: 270px;
    height: 11em;
    background: linear-gradient(270deg, #135f582c, #0d40303a, #10304433);
    background-size: 800% 800%;
    -webkit-animation: AnimationName 3s ease infinite;
    -moz-animation: AnimationName 3s ease infinite;
    animation: AnimationName 3s ease infinite;
    transition: .4s ease-in-out;
    border-radius: 7px;
    cursor: pointer;
  }
  
  .fl {
    display: flex;
    justify-content: flex-end;
    opacity: 0;
    z-index: 9999;
    transition: .2s ease-in-out;
  }
  
  .fl:hover .fullscreen {
    scale: 1.2;
  }
  
  .fl:hover .fullscreen_svg {
    fill: white;
  }
  
  .fullscreen {
    width: 1.5em;
    height: 1.5em;
    border-radius: 5px;
    background-color: #727890;
    margin: 1em;
    margin-right: 0.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .2s ease-in-out;
    box-shadow: 2px 2px 6px rgba(0,0,0,.4);
  }
  
  .fullscreen_svg {
    width: 15px;
    height: 15px;
    fill: rgb(177, 176, 176);
    transition: .2s ease-in-out;
  }
  
  .card_back {
    position: absolute;
    width: 15em;
    height: 13em;
    background-color: rgba(30, 31, 38, 0.575);
    border-radius: 7px;
    margin-top: -4.7em;
    margin-left: 0.7em;
    transition: .2s ease-in-out;
    z-index: -1;
  }
  
  .main:hover .card_back {
    margin-top: -5.9em;
    margin-left: 0em;
    scale: 1.1;
    height: 15.25em;
    cursor: pointer;
  }
  
  .main:hover .fl {
    opacity: 1;
    cursor: pointer;
    margin-right: 0.5em;
  }
  
  .data {
    display: flex;
    flex-direction: row;
    margin-top: 5em;
    margin-left: 1em;
  }
  
  .img {
    width: 2.25em;
    height: 2.25em;
    background-color: #252525;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .text {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-left: 0.5em;
    font-family: Montserrat;
    color: white;
  }
  
  .text_m {
    font-weight: bold;
    font-size: 0.9em;
  }
  
  .text_s {
    font-size: 0.7em;
  }
  
  .btns {
    display: flex;
    gap: 0.5em;
    padding-top: 1em;
    transition: .2s ease-in-out;
  }
  
  .comments {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5em;
    height: 1.4em;
    border-radius: 4px;
    margin-top: -0.5em;
    opacity: 0;
    background-color: #444857;
    transition: .24s ease-in-out;
  }
  
  .comments_text {
    font-family: Montserrat;
    font-size: 0.8em;
    margin-left: 0.25em;
    color: white;
  }
  
  .comments_svg {
    width: 12px;
    height: 12px;
    fill: white;
  }
  
  .comments:hover {
    background-color: #5A5F73;
    cursor: pointer;
  }
  
  .views {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3em;
    height: 1.4em;
    border-radius: 4px;
    margin-top: -0.5em;
    opacity: 0;
    background-color: #444857;
    transition: .28s ease-in-out;
  }
  
  .views_text {
    font-family: Montserrat;
    font-size: 0.8em;
    margin-left: 0.25em;
    color: white;
  }
  
  .views_svg {
    width: 12px;
    height: 12px;
    fill: white;
  }
  
  .views:hover {
    background-color: #5A5F73;
    cursor: pointer;
  }
  
  .main:hover .likes {
    opacity: 1;
    z-index: 2;
  }
  
  .main:hover .comments {
    opacity: 1;
    z-index: 2;
  }
  
  .main:hover .views {
    opacity: 1;
    z-index: 2;
  }
  
  
  
  /* The Main Switch */
  
  .card_content {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  @keyframes AnimationName {
    0% {
      background-position: 0% 50%
    }
  
    50% {
      background-position: 100% 50%
    }
  
    100% {
      background-position: 0% 50%
    }
  }
  