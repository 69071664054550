.card svg {
    height: 25px;
  }
  
  .card {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #e7e7e790;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25), 0 0 40px #177280 inset;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    overflow: hidden;
    height: 35px;
    width: 27px;
    border-radius: 5px;
  }
  
  .card::before, .card::after {
    position: absolute;
    display: flex;
    align-items: center;
    width: 50%;
    height: 100%;
    transition: 0.25s linear;
    z-index: 1;
  }
  
  .card::before {
    content: "";
    left: 0;
    justify-content: flex-end;
    background-color: #177280;
  }
  
  .card::after {
    content: "";
    right: 0;
    justify-content: flex-start;
    background-color: #0e5568;
  }
  
  .card:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    height: 45px;
    width: 160px;
  }
  
  .card:hover span {
    opacity: 0;
    z-index: -3;
  }
  
  .card:hover::before {
    opacity: 0.5;
    transform: translateY(-100%);
  }
  
  .card:hover::after {
    opacity: 0.5;
    transform: translateY(100%);
  }
  
  .card span {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: whitesmoke;
    font-family: 'Fira Mono', monospace;
    font-size: 20px;
    font-weight: 500;
    opacity: 1;
    transition: opacity 0.25s;
    z-index: 2;
  }
  
  .card .social-link {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 33%;
    height: 100%;
    color: whitesmoke;
    font-size: 24px;
    text-decoration: none;
    transition: 0.25s;
  }
  
  .card .social-link svg {
    text-shadow: 1px 1px rgba(31, 74, 121, 0.7);
    transform: scale(1);
  }
  
  .card .social-link:hover {
    background-color: rgba(249, 244, 255, 0.774);
    animation: bounce_613 0.4s linear;
  }
  
  @keyframes bounce_613 {
    40% {
      transform: scale(1.4);
    }
  
    60% {
      transform: scale(0.8);
    }
  
    80% {
      transform: scale(1.2);
    }
  
    100% {
      transform: scale(1);
    }
  }